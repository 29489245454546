// src/components/Clients.js
import React, { useState } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

const Clients = () => {
  const [settings] = useState({
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
  });

  return (
    <section className="clients-section centred pb_150 ">
      <div className="auto-container">
        <div className="sec-title mb_60">
          <h2>In Collaboration With</h2>
        </div>

        <Slider {...settings}>
          <a href="https://apeterminal.io/" target="_blank" rel="noopener noreferrer" data-website="mirada.ai">
            <img src="assets/images/clients/backed-client-1.png" alt="Ape Terminal" />
          </a>
          <a href="https://www.poolz.finance/" target="_blank" rel="noopener noreferrer" data-website="mirada.ai">
            <img src="assets/images/clients/backed-client-2.png" alt="Poolz Finance" />
          </a>
          <a href="https://ethereum.foundation/" target="_blank" rel="noopener noreferrer" data-website="mirada.ai">
            <img src="assets/images/clients/backed-client-3.png" alt="Ethereum" />
          </a>
          <a href="https://eesee.io/" target="_blank" rel="noopener noreferrer" data-website="mirada.ai">
            <img src="assets/images/clients/backed-client-4.png" alt="Eesee" />
          </a>
          <a href="https://li.fi/" target="_blank" rel="noopener noreferrer" data-website="mirada.ai">
            <img src="assets/images/clients/backed-client-5.png" alt="LiFi" />
          </a>
          <a href="https://hypergpt.ai/" target="_blank" rel="noopener noreferrer" data-website="mirada.ai">
            <img src="assets/images/clients/backed-client-6.png" alt="HyperGPT" />
          </a>
          <a href="https://carbon.website/" target="_blank" rel="noopener noreferrer" data-website="mirada.ai">
            <img src="assets/images/clients/backed-client-7.png" alt="Carbon Browser" />
          </a>
        </Slider>
      </div>
    </section>
  );
};

export default Clients;
