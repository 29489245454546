// src/components/Footer.js
import React from "react";

const Footer = () => {
  return (
    <footer className="main-footer style-two">
      <div className="auto-container">
        <div className="widget-section">
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
              <div className="footer-widget logo-widget">
                <figure className="footer-logo logo-size-override-footer">
                  <a href="https://mirada.ai">
                    <img src="https://mirada.ai/assets/images/logo-3.png" alt="Mirada AI" />
                  </a>
                </figure>
                <p>Revolutionizing digital experiences with AI and blockchain technology.</p>
                <ul className="social-links clearfix">
                  <li>
                    <a href="https://x.com/MiradaAI">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://t.me/MiradaAI">
                      <i className="fa-brands fa-telegram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/mirada-ai">
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
              <div className="footer-widget links-widget ml_50">
                <div className="widget-title">
                  <h3>Quick Link</h3>
                </div>
                <div className="widget-content">
                  <ul className="links-list clearfix">
                    <li>
                      <a href="https://mirada.ai/">Home</a>
                    </li>
                    <li>
                      <a href="https://mirada.ai/about">About Us</a>
                    </li>
                    <li>
                      <a href="https://medium.com/@MiradaLabs">Blog</a>
                    </li>
                    <li>
                      <a href="https://mirada.ai/dashboard">Analytics Dashboard</a>
                    </li>
                    <li>
                      <a href="https://mirada.ai/products">Products</a>
                    </li>
                    <li>
                      <a href="https://mirada.ai/careers">Careers</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
              <div className="footer-widget links-widget ml_30">
                <div className="widget-title">
                  <h3>Useful Links</h3>
                </div>
                <div className="widget-content">
                  <ul className="links-list clearfix">
                    <li>
                      <a target="_blank" href="https://docs.mirada.ai/community-guidelines/privacy-policy" rel="noreferrer">
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://docs.mirada.ai/community-guidelines/terms-of-service" rel="noreferrer">
                        Terms & Condition
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://mirada.ai/mediakit" rel="noreferrer">
                        Media Kit
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://mirada.ai/status" rel="noreferrer">
                        Server Status
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://mirada.ai/contact" rel="noreferrer">
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
              <div className="footer-widget contact-widget">
                <div className="widget-title">
                  <h3>Contact</h3>
                </div>
                <div className="widget-content">
                  <p>For business inquiries please reach out below.</p>
                  <ul className="contact-info clearfix">
                    <li>
                      <i className="icon-29"></i>
                      <a href="mailto:hi@mirada.ai">hi@mirada.ai</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom centred">
        <p>
          Copyright &copy; 2024 <a href="https://mirada.ai">Mirada AI</a>, Inc. All Rights Reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
