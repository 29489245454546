// src/components/About.js
import React, { useState, useEffect } from "react";
import useLoadResources from "../../hooks/useLoadResources";

import Preloader from "../../components/Preloader";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import ChooseUs from "../../components/ChooseUs";
import Clients from "../../components/Clients";
import Features from "../../components/Features";
import FunFact from "../../components/FunFact";
import FAQ from "../../components/FAQ";
import CTA from "../../components/CTA";
import Footer from "../../components/Footer";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ChatBot from "../../components/chatbot/ChatBot";

const Home = () => {

  const [settings] = useState({
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
  });

  const resources = [
    { type: "script", src: "../../assets/js/jquery.js" },
    { type: "script", src: "../../assets/js/bootstrap.min.js" },
    { type: "script", src: "../../assets/js/owl.js" },
    { type: "script", src: "../../assets/js/wow.js" },
    { type: "script", src: "../../assets/js/jquery.fancybox.js" },
    { type: "script", src: "../../assets/js/appear.js" },
    { type: "script", src: "../../assets/js/isotope.js" },
    { type: "script", src: "../../assets/js/jquery.nice-select.min.js" },
    { type: "script", src: "../../assets/js/aos.js" },
    { type: "script", src: "../../assets/js/script.js" },
    { type: "link", src: "../../assets/css/font-awesome-all.css" },
    { type: "link", src: "../../assets/css/flaticon.css" },
    { type: "link", src: "../../assets/css/owl.css" },
    { type: "link", src: "../../assets/css/bootstrap.css" },
    { type: "link", src: "../../assets/css/jquery.fancybox.min.css" },
    { type: "link", src: "../../assets/css/animate.css" },
    { type: "link", src: "../../assets/css/aos.css" },
    { type: "link", src: "../../assets/css/nice-select.css" },
    { type: "link", src: "../../assets/css/elpath.css" },
    { type: "link", src: "../../assets/css/color.css" },
    { type: "link", src: "../../assets/css/style.css" },
    { type: "link", src: "../../assets/css/module-css/page-title.css" },
    { type: "link", src: "../../assets/css/module-css/feature.css" },
    { type: "link", src: "../../assets/css/module-css/clients.css" },
    { type: "link", src: "../../assets/css/module-css/chooseus.css" },
    { type: "link", src: "../../assets/css/module-css/cta.css" },
    { type: "link", src: "../../assets/css/module-css/testimonial.css" },
    { type: "link", src: "../../assets/css/module-css/faq.css" },
    { type: "link", src: "../../assets/css/responsive.css" },
    { type: "link", src: "../../assets/css/chatbot.scss" },
  ];

  useLoadResources(resources);

  const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPreloader(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {showPreloader && <Preloader />}
      <Header />
      <Banner />
      <ChooseUs />
      <Clients />
      <Features />
      <FunFact />

      <section class="feature-style-three pb_150">
        <div class="auto-container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 col-sm-12 content-column">
              <div class="content_block_one">
                <div class="content-box mr_50">
                  <div class="sec-title mb_50">
                    <span class="sub-title">Advanced Platform</span>
                    <h2>Access our advanced AI platform</h2>
                  </div>
                  <div class="inner-box">
                    <div class="single-item">
                      <h3>Full-Spectrum AI Capabilities</h3>
                      <p>Generate stunning visuals, state of the art animations, audio, and interact with our advanced language models all within our online platform.</p>
                    </div>
                    <div class="single-item">
                      <h3>Mobile Bots</h3>
                      <p>If you prefer a mobile interface, our AI is also accessible via Telegram and Discord. You can also use them in your groups and channels!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 image-column">
              <div class="image_block_two">
                <div class="image-box pr_45 pt_40 pl_100 ml_40 pb_65">
                  <figure class="image image-1 platform-image-front" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
                    <img src="assets/images/resource/dashboard-5.jpg" alt="" />
                  </figure>
                  <figure class="image image-2 platform-image-back" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="700">
                    <img src="assets/images/resource/dashboard-6.jpg" alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="clients-section centred pb_150" style={{ background: "#fff" }}>
        <div class="auto-container">
          <div class="sec-title mb_60">
            <h2>As seen in</h2>
          </div>

          <Slider {...settings}>
            <img src="assets/images/clients/publication-1.png" alt="" />
            <img src="assets/images/clients/publication-2.png" alt="" />
            <img src="assets/images/clients/publication-4.1.png" alt="" />
            <img src="assets/images/clients/publication-4.png" alt="" />
            <img src="assets/images/clients/publication-5.png" alt="" />
          </Slider>
        </div>
      </section>
      <FAQ />
      <CTA />
      <Footer />

      <ChatBot />

      <div class="scroll-to-top">
        <div>
          <div class="scroll-top-inner">
            <div class="scroll-bar">
              <div class="bar-inner"></div>
            </div>
            <div class="scroll-bar-text">Go To Top</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
