// src/components/FunFact.js
import React from "react";

const FunFact = () => {
  return (
    <section className="funfact-section centred mb_100">
      <div className="auto-container">
        <div className="inner-container" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="900">
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 funfact-block">
              <div className="funfact-block-one">
                <div className="inner-box">
                  <div className="count-outer count-box">
                    <span className="count-text" data-speed="1500" data-stop="20000">
                      0
                    </span>
                    <span>+</span>
                  </div>
                  <p>Daily AI Requests</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 funfact-block">
              <div className="funfact-block-one">
                <div className="inner-box">
                  <div className="count-outer count-box">
                    <span className="count-text" data-speed="1500" data-stop="65000">
                      0
                    </span>
                    <span>+</span>
                  </div>
                  <p>Users</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 funfact-block">
              <div className="funfact-block-one">
                <div className="inner-box">
                  <div className="count-outer count-box">
                    <span></span>
                    <span className="count-text" data-speed="1500" data-stop="6000">
                      0
                    </span>
                    <span>+</span>
                  </div>
                  <p>Members</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 funfact-block">
              <div className="funfact-block-one">
                <div className="inner-box">
                  <div className="count-outer count-box">
                    <span className="count-text" data-speed="1500" data-stop="5">
                      0
                    </span>
                  </div>
                  <p>Integrations</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FunFact;
