// src/components/FAQ.js
import React from "react";

const FAQ = () => {
  return (
    <section className="faq-style-two home-2 faq-dark-section pb_150">
      <div className="pattern-layer" style={{ backgroundImage: "url(assets/images/shape/shape-8.png)" }}></div>
      <div className="auto-container">
        <div className="sec-title mb_60 centred">
          <span className="sub-title">General Faqs</span>
          <h2>Frequently Asked Questions</h2>
        </div>
        <div className="inner-box">
          <ul className="accordion-box">
            <li className="accordion block active-block">
              <div className="acc-btn active">
                <div className="icon-box"></div>
                <h5>Can I use Mirada AI for free?</h5>
              </div>
              <div className="acc-content current">
                <div className="text">
                  <p>Yes! Our AI services are available for free and for paid users. Think of our free-tier as a trial for something even better.</p>
                </div>
              </div>
            </li>
            <li className="accordion block">
              <div className="acc-btn">
                <div className="icon-box"></div>
                <h5>What can I do with Mirada AI?</h5>
              </div>
              <div className="acc-content">
                <div className="text">
                  <p>
                    You can generate images, animation, and audio. You can also chat with our AI to ask it questions and make your own AI assistant. We are also working on a new search feature that combines search & AI to give you more accurate and relevant answers faster. We are releasing new
                    features all the time, make sure you're staying up to date!
                  </p>
                </div>
              </div>
            </li>
            <li className="accordion block">
              <div className="acc-btn">
                <div className="icon-box"></div>
                <h5>Why is the team non-doxed?</h5>
              </div>
              <div className="acc-content">
                <div className="text">
                  <p>Our core team decided to remain anonymous to protect their privacy which we believe is keeping in the spirit of the decentralized space as many of the main blockchains today were founded anonymously, eg Bitcoin.</p>
                </div>
              </div>
            </li>
            <li className="accordion block">
              <div className="acc-btn">
                <div className="icon-box"></div>
                <h5>How can I access Mirada AI?</h5>
              </div>
              <div className="acc-content">
                <div className="text">
                  <p>You can access our AI via our Telegram & Discord bots, our web platform, and our third-party integration partners like Carbon Browser.</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
