/* eslint-disable jsx-a11y/anchor-is-valid */
// src/components/ChooseUs.js
import React from "react";

const ChooseUs = () => {
  return (
    <section className="chooseus-section pt_25 pb_150 centred" style={{ background: "#FBFAF9" }}>
      <div className="auto-container">
        <div className="sec-title mb_20">
          {/* <span class="sub-title" style={{marginBottom: 8}}>Product</span> */}
          <h2>Key services of Mirada AI</h2>
        </div>
        <div className="row clearfix">
          <div className="col-lg-3 col-md-6 col-sm-12 chooseus-block">
            <div className="chooseus-block-one chooseus-block-nextgen panel-light-border" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="900">
              <div className="inner-box inner-box-fixed">
                <div className="icon-box">
                  <i className="icon-16"></i>
                </div>
                <h3>
                  <a>Generative AI</a>
                </h3>
                <p>Transform your creative ideas into stunning visuals and animations, and chat with our intelligent LLM for instant, smart interactions. Experience the future of digital creativity with ease.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 chooseus-block">
            <div className="chooseus-block-one chooseus-block-nextgen panel-light-border" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="700">
              <div className="inner-box inner-box-fixed">
                <div className="icon-box">
                  <i className="icon-15"></i>
                </div>
                <h3>
                  <a>AI Search</a>
                </h3>
                <p>Enhance your search experience with our AI-driven technology, designed to deliver relevant and precise answers with unprecedented speed.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 chooseus-block">
            <div className="chooseus-block-one chooseus-block-nextgen panel-light-border" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1100">
              <div className="inner-box inner-box-fixed">
                <div className="icon-box">
                  <i className="icon-17"></i>
                </div>
                <h3>
                  <a>Bots & Automation</a>
                </h3>
                <p>Streamline your community management with our fully customizable bots, designed to automate tasks, reduce overheads, and enhance engagement efficiently.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 chooseus-block">
            <div className="chooseus-block-one chooseus-block-nextgen panel-light-border" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1300">
              <div className="inner-box inner-box-fixed">
                <div className="icon-box">
                  <i className="icon-21"></i>
                </div>
                <h3>
                  <a>Third-party Integrations</a>
                </h3>
                <p>Mirada AI’s robust API & SDK enables seamless integration and improved user experiences across diverse third-party applications.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseUs;
